function ready(fn) {
	if (document.readyState != 'loading'){
		fn();
	} else {
		document.addEventListener('DOMContentLoaded', fn);
	}
}


/*
 *	COOKIEJS
 */

window.NtrigaConsent = {
	All: false,
	EssentialCookies: false,
	AnalyticalCookies: false,
	MarketingCookies: false,
	VideoCookies: false,
};

ready(function() {
	// Vars
	var btn_show = document.querySelector('#ntr-cookie-show');
	var popup = document.querySelector('#ntr-cookies');
	var btn_accept = document.querySelector('#ntr-cookie-accept');
	var btn_accept_all = document.querySelector('#ntr-cookie-accept-all');
	var btn_options = document.querySelector('#ntr-cookie-options');
	var btn_decline = document.querySelector('#ntr-cookie-decline');
	var btn_save = document.querySelector('#ntr-cookie-save');

	var consent_options = document.querySelector('#ntr-cookies-options');
	var consent_buttons = document.querySelector('#ntr-cookies-buttons');
	var consent_checkboxes = document.querySelectorAll('[data-consent-for]');

	if (popup) {
		// Show?
		if (!Cookies.get('ntriga-cookie-chosen')) {
			popup.classList.remove('hidden');
		}

		// Load prior consent
		var checked_count = 0;

		for (var i=0; i < consent_checkboxes.length; i++) {
			var checkbox = consent_checkboxes[i];
			var cookie_name = checkbox.getAttribute('data-consent-for');

			if (Cookies.get(cookie_name)) {
				var consent_value = (Cookies.get(cookie_name) === 'true' || Cookies.get(cookie_name) === true);
				NtrigaConsent[cookie_name] = consent_value;
				checkbox.checked = consent_value;
			}

			if (checkbox.checked) {
				checked_count++;
			}
		}

		setConsent('All', (checked_count == consent_checkboxes.length));

		// Accept
		btn_accept.addEventListener('click', function() {
			consentAll(consent_checkboxes);
			popup.classList.add('hidden');
		});

		btn_accept_all.addEventListener('click', function() {
			consentAll(consent_checkboxes);
			popup.classList.add('hidden');
		});

		// Refuse
		btn_decline.addEventListener('click', function() {
			consentNone(consent_checkboxes)
			popup.classList.add('hidden');
		});

		// Select
		btn_options.addEventListener('click', function() {
			consent_options.classList.remove('d-none');
			consent_buttons.classList.add('d-none');
		}, false);

		// Save
		btn_save.addEventListener('click', function() {
			consentSave(consent_checkboxes);
			consent_options.classList.add('d-none');
			consent_buttons.classList.remove('d-none');
			popup.classList.add('hidden');
		});

		// Show popup
		btn_show.addEventListener('click', function() {
			popup.classList.remove('hidden');
		});
	}
});

function consentAll(consent_checkboxes) {
	for (var i=0; i < consent_checkboxes.length; i++) {
		var checkbox = consent_checkboxes[i];
		var cookie_name = checkbox.getAttribute('data-consent-for');

		checkbox.checked = true;
		setConsent(cookie_name, true);
	}

	setConsent('All', true);

	consentUpdated();
}

function consentNone(consent_checkboxes) {
	for (var i=0; i < consent_checkboxes.length; i++) {
		var checkbox = consent_checkboxes[i];
		var cookie_name = checkbox.getAttribute('data-consent-for');

		checkbox.checked = false;
		setConsent(cookie_name, false);
	}

	setConsent('All', false);

	consentUpdated();
}

function consentSave(consent_checkboxes) {
	var checked_count = 0;

	for (var i=0; i < consent_checkboxes.length; i++) {
		var checkbox = consent_checkboxes[i];
		var cookie_name = checkbox.getAttribute('data-consent-for');

		setConsent(cookie_name, checkbox.checked);

		if (checkbox.checked) {
			checked_count++;
		}
	}

	setConsent('All', (checked_count == consent_checkboxes.length));

	consentUpdated();
}

function setConsent(cookie_name, value) {
	NtrigaConsent[cookie_name] = value;
	Cookies.set(cookie_name, value, { expires: 365 });
}

function consentUpdated() {
	Cookies.set('ntriga-cookie-chosen', true, { expires: 365 });

	if (typeof dataLayer !== 'undefined') {
		dataLayer.push({'event':'cookie_consent_marketing'});
	}
}
